import { TFunction } from 'react-i18next';

// Common function for translation.
export type TFunc = TFunction<'translation', undefined>;

// Status type for Select/Multiselect components
export enum DropdownStatus {
  PENDING = 'pending',
  LOADING = 'loading',
  FINISHED = 'finished',
  ERROR = 'error',
}
