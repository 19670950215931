import Button from '@cloudscape-design/components/button';
import { FlashbarProps } from '@cloudscape-design/components/flashbar';
import { TableProps } from '@cloudscape-design/components/table';
import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

export interface ErrorItem {
  message: string;
  rowIds: number[];
}

interface Error<T> {
  visible: boolean;
  items: T[];
  columnDefinitions?: TableProps.ColumnDefinition<T>[];
}

interface NotificationContextType {
  error: Error<any>;
  notifications: FlashbarProps.MessageDefinition[];
  addNotification(notification: FlashbarProps.MessageDefinition): void;
  clearNotification(): void;
  clearNotificationById(id: string): void;
  addError(
    callback: (notification: FlashbarProps.MessageDefinition) => void,
    content: string,
    tableItems: any[],
    columnDefinitions?: TableProps.ColumnDefinition<any>[],
  ): void;
  clearError(): void;
}

const defaultError: Error<any> = {
  visible: false,
  items: [],
};

/**
 * React context for Notifications
 */
const NotificationContext = createContext<NotificationContextType>({
  notifications: [],
  error: { visible: false, items: [] },
  addNotification: () => {},
  clearNotification: () => {},
  clearNotificationById: () => {},
  addError: () => {},
  clearError: () => {},
});

/**
 * Props for NotificationProvider
 */
interface NotificationProviderProps {
  children: ReactNode;
}

/**
 * Wrapper to allow notifications in application
 */
export const NotificationProvider: FC<NotificationProviderProps> = ({
  children,
}) => {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [error, setError] = useState<Error<any>>(defaultError);

  const addError = useCallback(
    (
      callback: (notification: FlashbarProps.MessageDefinition) => void,
      content: string,
      tableItems: any[],
      columnDefinitions: TableProps.ColumnDefinition<any>[],
    ) => {
      callback({
        type: 'error',
        content,
        action: tableItems.length ? (
          <Button
            data-testid="notification-view-btn"
            onClick={() =>
              setError({ visible: true, items: tableItems, columnDefinitions })
            }
          >
            {t('view')}
          </Button>
        ) : null,
      });
    },
    [t],
  );

  const clearError = useCallback(() => setError(defaultError), []);

  const addNotification = useCallback(
    (message: FlashbarProps.MessageDefinition) => {
      setNotifications((notifications) => [...notifications, message]);
    },
    [],
  );

  const clearNotification = useCallback(() => {
    setNotifications([]);
  }, []);

  const clearNotificationById = useCallback((id: string) => {
    setNotifications((currentNotifications) =>
      currentNotifications.filter((n) => n.id !== id),
    );
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        error,
        notifications,
        addNotification,
        clearNotification,
        clearNotificationById,
        addError,
        clearError,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

/**
 * React hook to use notification context
 * @returns
 */
export const useNotificationContext = () => useContext(NotificationContext);
