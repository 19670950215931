import { AnnotationContextProps } from '@cloudscape-design/components';
import { TFunc } from '../types';

export default (t: TFunc): AnnotationContextProps.I18nStrings => ({
  stepCounterText: (stepIndex, totalStepCount) =>
    t('step_counter', {
      currentStep: stepIndex + 1,
      totalSteps: totalStepCount,
    }),
  taskTitle: (taskIndex, taskTitle) =>
    t('task_title', { index: taskIndex + 1, title: taskTitle }),
  labelHotspot: (openState, stepIndex, totalStepCount) =>
    openState
      ? t('close_annotation', {
          stepIndex: stepIndex + 1,
          totalSteps: totalStepCount,
        })
      : t('open_annotation', {
          stepIndex: stepIndex + 1,
          totalSteps: totalStepCount,
        }),
  nextButtonText: t('next'),
  previousButtonText: t('previous'),
  finishButtonText: t('finish'),
  labelDismissAnnotation: t('hide_annotation'),
});
