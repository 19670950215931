/**
 * Configuration required for Amplify.
 * Ref: https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/
 */

export const envConfig = {
  Auth: {
    // (required) only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: 'us-west-2:358ea1ba-f1ed-446a-a564-5e342fabdae3',

    // (required)- Amazon Cognito Region
    region: 'us-west-2',

    // (optional) - Amazon Cognito User Pool ID
    userPoolId: 'us-west-2_pl4Z3PrrS',

    // (optional) - Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)
    userPoolWebClientId: '1ucvli5ct8a1epqkccq43k82qi',

    // (optional) - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    // (optional) - Hosted UI configuration
    oauth: {
      domain: 'mec-access-control-prod' + '.auth.us-west-2.amazoncognito.com',
      redirectSignIn: 'https://' + window.location.host + '/',
      redirectSignOut: 'https://' + window.location.host + '/',
      scope: ['openid', 'email', 'profile'],
      responseType: 'code',
    },
  },
  API: {
    endpoints: [
      {
        name: 'GalaxySharedServiceAPI',
        endpoint: 'https://fkokh6z8j7.execute-api.us-west-2.amazonaws.com/prod',
      },
      {
        name: 'MECAPI',
        endpoint: 'https://1el2xwoh02.execute-api.us-west-2.amazonaws.com/prod',
      },
      {
        name: 'GalaxyQueryService',
        endpoint: 'https://vs6dzbvi5i.execute-api.us-west-2.amazonaws.com/prod',
      },
      {
        name: 'SettingService',
        endpoint: 'https://uc88pjn3vl.execute-api.us-west-2.amazonaws.com/prod',
      },
      {
        name: 'JournalEntryService',
        endpoint: 'https://api.journal-entry-service.mec.asft.amazon.dev',
      },
      {
        name: 'RuleService',
        endpoint: 'https://api.rule-service.mec.asft.amazon.dev',
      },
    ],
  },
  Storage: {
    AWSS3: {
      bucket: 'fox-mec-prod',
      region: 'us-west-2',
    },
  },
};
