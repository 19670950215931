import {
  AppLayoutProps,
  Box,
  Header,
  Link,
  NonCancelableCustomEvent,
  TutorialPanelProps,
} from '@cloudscape-design/components';
import HelpPanel from '@cloudscape-design/components/help-panel';
import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TFunc } from '../types';
import { useAvailableTutorials } from './useAvailableTutorials';

export enum ToolsTab {
  HELP_PANEL = 'help-panel',
  TUTORIALS_PANEL = 'tutorials-panel',
}

export interface Tool {
  title: string;
  content: string;
  links: { href: string; text: string }[];
}

const getFormattedToolContent = (tool: Tool, t: TFunc) => (
  <HelpPanel
    data-testid="help-panel"
    header={
      <Header data-testid="help-panel-title" variant="h2">
        {t(tool.title)}
      </Header>
    }
    footer={
      <>
        <Header variant="h3">{t('learn_more')}</Header>
        <ul>
          {tool.links.map((link, index) => (
            <li key={`help-content-${index}`}>
              <Link href={link.href}>{t(link.text)}</Link>
            </li>
          ))}
        </ul>
      </>
    }
  >
    <Box data-testid="help-panel-content">
      <p>
        <Trans t={t} i18nKey={tool.content} transSupportBasicHtmlNodes />
      </p>
    </Box>
  </HelpPanel>
);

export const useTools = () => {
  const [toolsTab, setToolsTab] = useState<ToolsTab>(ToolsTab.HELP_PANEL);
  const [infoPanelContent, setInfoPanelContent] = useState<ReactNode>();
  const [toolsOpen, setToolsOpen] = useState(false);
  const { t } = useTranslation();
  const { tutorial, setTutorialCompleted } = useAvailableTutorials();

  const setFormattedToolContent = useCallback(
    (tool: Tool) => {
      setInfoPanelContent(getFormattedToolContent(tool, t));
    },
    [t],
  );

  const setHelpPanelContent = useCallback(
    (tool: Tool) => {
      if (tool) {
        setFormattedToolContent(tool);
      }
    },
    [setFormattedToolContent],
  );

  const toggleToolPanel = useCallback(() => {
    setToolsOpen(!toolsOpen);
  }, [toolsOpen]);

  const onToolsChange = useCallback(
    (e: NonCancelableCustomEvent<AppLayoutProps.ChangeDetail>) => {
      setToolsOpen(e.detail.open);
    },
    [],
  );

  return {
    infoPanelContent,
    setHelpPanelContent,
    toolsOpen,
    toolsTab,
    tutorial,
    setToolsTab,
    toggleToolPanel,
    onToolsChange,
    setTutorialCompleted,
  };
};

interface ToolsContextType {
  infoPanelContent: ReactNode;
  setHelpPanelContent: (tool: Tool) => void;
  toolsOpen: boolean;
  toolsTab: ToolsTab;
  toggleToolPanel: () => void;
  onToolsChange: (
    e: NonCancelableCustomEvent<AppLayoutProps.ChangeDetail>,
  ) => void;
  setToolsTab: (tabId: ToolsTab) => void;
  tutorial?: TutorialPanelProps.Tutorial;
  setTutorialCompleted: (
    tutorial: TutorialPanelProps.Tutorial,
    completed: boolean,
  ) => void;
}

const ToolsContext = createContext<ToolsContextType>({
  infoPanelContent: undefined,
  toolsTab: ToolsTab.HELP_PANEL,
  setToolsTab: () => {},
  setHelpPanelContent: () => {},
  toolsOpen: false,
  toggleToolPanel: () => {},
  onToolsChange: () => {},
  tutorial: undefined,
  setTutorialCompleted: () => {},
});

interface ToolsProviderProps {
  children: ReactNode;
}

export const ToolsProvider: React.FC<ToolsProviderProps> = ({ children }) => {
  const tools = useTools();

  return (
    <ToolsContext.Provider value={tools}>{children}</ToolsContext.Provider>
  );
};

export const useToolsContent = () => useContext(ToolsContext);
