import Spinner from '@cloudscape-design/components/spinner';
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify, Auth } from 'aws-amplify';
import React, { useEffect } from 'react';
import { envConfig } from '../../configuration/aws-exports';

interface AuthProviderProps {
  children: JSX.Element;
}

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  useEffect(() => {
    const configure = async () => {
      try {
        Amplify.configure(envConfig);
        await Auth.currentAuthenticatedUser();
      } catch {
        await Auth.federatedSignIn({ customProvider: 'AmazonFederate' });
      }
    };
    configure();
  }, []);

  return (
    <React.Suspense fallback={<Spinner />}>
      <Authenticator.Provider>{children}</Authenticator.Provider>
    </React.Suspense>
  );
};
