import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AnnotationContextComponent from '@cloudscape-design/components/annotation-context';
import annotationContexti18nStrings from '../i18nStrings/annotationContexti18nStrings';
import { useToolsContent } from './ToolsProvider';

interface AnnotationContextProps {
  children: React.ReactNode;
}

export const AnnotationContext: React.FC<AnnotationContextProps> = ({
  children,
}) => {
  const { t } = useTranslation();
  const [isTutorialVisible, setTutorialIsVisible] = useState<boolean>(false);
  const { tutorial, setTutorialCompleted } = useToolsContent();

  return (
    <AnnotationContextComponent
      currentTutorial={isTutorialVisible && tutorial ? tutorial : null}
      onStartTutorial={(event) => {
        const tutorial = event.detail.tutorial;
        setTutorialCompleted(tutorial, false);
        setTutorialIsVisible(true);
      }}
      onExitTutorial={() => setTutorialIsVisible(false)}
      onFinish={() => {
        if (!isTutorialVisible || !tutorial) {
          return;
        }
        setTutorialCompleted(tutorial, true);
        setTutorialIsVisible(false);
      }}
      i18nStrings={annotationContexti18nStrings(t)}
    >
      {children}
    </AnnotationContextComponent>
  );
};
