import { Box, Container, Header } from '@cloudscape-design/components';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router-dom';
import { useAwsRum } from 'src/provider/AwsRumProvider';

interface RouteError {
  message?: string;
  data?: string;
}

export const ErrorBoundary = () => {
  const { t } = useTranslation();
  const error = useRouteError() as RouteError;
  const { recordError } = useAwsRum();

  const errorMessage = useMemo(() => {
    if (!error) return '';
    return error.message ?? error.data ?? JSON.stringify(error);
  }, [error]);

  useEffect(() => {
    if (errorMessage) {
      console.error(errorMessage);
      recordError(errorMessage);
    }
  }, [errorMessage, recordError]);

  return (
    <Box margin="xxl" padding="xxl" data-testid="error-page">
      <Container header={<Header>{t('error_boundary_header')}</Header>}>
        <p>{t('error_boundary_message')}</p>
      </Container>
    </Box>
  );
};
